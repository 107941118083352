<!--我的订单-->
<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="chaneTabs" @change="chaneTabs">
			<el-tab-pane>
				<span slot="label">全部订单</span>
				<div class="search">
					<div><input type="text" v-model="parameter.KeyWord" placeholder="订单号/商品名称/商品编号" /></div>
					<div class="icon"><i style="cursor: pointer" class="iconfont iconsearch" @click="search"></i></div>
					<div class="select">
						<el-select v-model="parameter.DateType" @change="init" placeholder="请选择" size="medium">
							<el-option label="近三个月订单" :value="1"></el-option>
							<el-option label="近六个月订单" :value="2"></el-option>
							<el-option label="全部订单" :value="0"></el-option>
						</el-select>
					</div>
				</div>
				<div class="list">
					<div>
						<table border="0" cellspacing="0" cellpadding="0">
							<tr class="list-title">
								<th width="450">商品信息</th>
								<th width="110">单价(元)</th>
								<th width="110">数量</th>
								<th width="110">商品操作</th>
								<th width="110">实付款(元)</th>
								<th width="110">全部状态</th>
								<th width="180">交易操作</th>
							</tr>
						</table>

						<!--订单号-->
						<div v-if="cartList.length > 0">
							<table
								style="border-collapse:separate;
                                border-spacing:0 10px;"
								border="0"
								cellspacing="0"
								cellpadding="0"
								v-for="(item, index) in cartList"
								:key="index"
							>
								<tr class="order-mian" style="margin:10px">
									<td
										width="450"
										style="padding-left:10px;
                                        color: #999999;"
									>
										{{ item.CreatTime }} 订单号
										<span class="order-number">{{ item.OrderID }}</span>
									</td>
									<td width="110" v-for="j in 5" :key="j"></td>
									<td width="220" style="text-align:right;display: flex;justify-content: flex-end;">
										<div @click="delOrder(item)" v-if="item.State == 5 || item.State == 4 || item.State == 3" style="margin-right:10px;margin-top:5px;cursor: pointer;">
											<i class="iconfont icondustbin_icon"></i>
										</div>
										<a href="javascript:;" title="客服" @click="toChat(item)" style="margin-right:30px;margin-top:5px;color: #666666;">
											<i slot="reference" class="iconfont iconshouhou"></i>
										</a>
									</td>
								</tr>
								<tr class="order-mian-list" v-for="(j, i) in item.DetailList" :key="i">
									<td style="display: flex" class="border">
										<div><img :src="pic + j.ProPictrue" style="cursor: pointer;" @click="handleTo(j)" alt="" /></div>
										<div class="order-mian-list-text">
											<div class="proName" style="cursor: pointer;" @click="handleTo(j)">{{ j.ProName }}</div>
											<div class="sku">SKU: {{ j.BarCode }}</div>
										</div>
									</td>
									<td class="border">
										<div>￥{{ j.ProUnitPrice }}</div>
									</td>
									<td class="border">
										<div>X {{ j.PCount }}</div>
									</td>
									<td class="border-right">
										<div class="operation"></div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 1" @click="view(j)">审核中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 6" @click="view(j)">退款中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 7" @click="view(j)">退款完成</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 8" @click="view(j)">退款失败</div>
										<div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;" v-if="j.ExState == 9">
											<div style="color: #DC2310;cursor: pointer;" @click="view(j)">部分已退款</div>
											<div style="cursor: pointer;" class="button" @click="refund(item, j)"><span>申请售后</span></div>
										</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 10" @click="view(j)">退换审核失败</div>
										<!-- 1已付款 2已发货 3已收货 4已完成 -->

										<template v-if="j.ClassURL">
											<el-button
												type="warning"
												plain
												size="mini"
												v-if="(item.State == 1 || item.State == 2 || item.State == 3 || item.State == 4) && j.ExState == 0"
												@click="toLesson(j.ClassURL)"
											>
												去使用
											</el-button>
										</template>
										<template v-if="!j.ClassURL">
											<el-button size="mini" v-if="(item.State == 1 || item.State == 2 || item.State == 3) && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
											<el-button size="mini" v-if="item.State == 4 && item.show && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
										</template>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div class="subtotal">￥{{ item.TotalPrice.toFixed(2) }}</div>
										<div class="button">
											<span v-if="item.OrderType == 1">手机订单</span>
											<span v-else>电脑订单</span>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 6"><div style="color:#999999;margin-top:10px">交易关闭</div></div>

											<div>
												<span v-if="item.State == 0" class="stay">待付款</span>
												<span v-if="item.State == 1" style="color: #1B98AA; ">待发货</span>
												<span v-if="item.State == 2" style="color: #1B98AA; ">卖家已发货</span>
												<span v-if="item.State == 3" style="color: #999999;">待评价</span>
												<span v-if="item.State == 4" style="color: #999999;">已完成</span>
												<span v-if="item.State == 5" class="cancel-order">已取消</span>
											</div>

											<el-popover placement="bottom" width="300" @show="showLogistics(item)" trigger="hover">
												<div class="information">
													<div class="information-title">{{ information.ComName }} {{ item.TrackingNum }}</div>
													<div class="information-main" v-for="(item, index) in information.TrackList" :key="index">
														<div>
															<span style="color:rgb(27, 152, 170)">{{ item.ftime }}</span>
															{{ item.context }}
														</div>
													</div>
												</div>
												<div slot="reference" class="track" v-if="item.State == 2">
													<i class="iconfont iconwuliu"></i>
													<span>追踪</span>
												</div>
											</el-popover>
											<div class="order-details" @click="toOrderDetails(item)">订单详情</div>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 0">
												<div class="paidui">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.time }}</span>
												</div>
												<div v-if="item.time != '已作废'" class="handle" @click="handlePay(item)">支付</div>
												<div v-if="item.time != '已作废'" class="cancel" @click="cancel(item)">取消订单</div>
											</div>
											<div class="remind" @click="remind(item)" v-if="item.State == 1">提醒发货</div>
											<div v-if="item.State == 2">
												<div class="paidui" v-if="!item.EX">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.confirmTime }}</span>
												</div>
												<div @click="confirm(item)" class="confirm">确认收货</div>
											</div>
											<div v-if="item.State == 3"><div @click="toEvaluate(item)" class="confirm">去评价</div></div>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="pagination">
						<div>
							<el-pagination
								background
								@size-change="changeSize"
								@current-change="changeCurrent"
								:page-sizes="[10, 50, 100, 200]"
								:page-size="20"
								layout="total, sizes, prev, pager, next, jumper"
								:total="count"
							></el-pagination>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane>
				<span slot="label">待付款</span>
				<div class="search">
					<div><input type="text" v-model="parameter.KeyWord" placeholder="订单号/商品名称/商品编号" /></div>
					<div class="icon"><i style="cursor: pointer" class="iconfont iconsearch" @click="search"></i></div>
					<div class="select">
						<el-select v-model="parameter.DateType" @change="init" placeholder="请选择" size="medium">
							<el-option label="近三个月订单" :value="1"></el-option>
							<el-option label="近六个月订单" :value="2"></el-option>
							<el-option label="全部订单" :value="0"></el-option>
						</el-select>
					</div>
				</div>
				<div class="list">
					<div>
						<table border="0" cellspacing="0" cellpadding="0">
							<tr class="list-title">
								<th width="450">商品信息</th>
								<th width="110">单价(元)</th>
								<th width="110">数量</th>
								<th width="110">商品操作</th>
								<th width="110">实付款(元)</th>
								<th width="110">全部状态</th>
								<th width="180">交易操作</th>
							</tr>
						</table>

						<!--订单号-->
						<div v-if="cartList.length > 0">
							<table
								style="border-collapse:separate;
                                border-spacing:0 10px;"
								border="0"
								cellspacing="0"
								cellpadding="0"
								v-for="(item, index) in cartList"
								:key="index"
							>
								<tr class="order-mian" style="margin:10px">
									<td
										width="450"
										style="padding-left:10px;
                                        color: #999999;"
									>
										{{ item.CreatTime }} 订单号
										<span class="order-number">{{ item.OrderID }}</span>
									</td>
									<td width="110" v-for="j in 5" :key="j"></td>
									<td width="220" style="text-align:right">
										<div @click="delOrder(item)" v-if="item.State == 5 || item.State == 4 || item.State == 3" style="margin-right:30px;margin-top:5px">
											<i class="iconfont icondustbin_icon"></i>
										</div>

										<div
											style="margin-top:5px;
                                            cursor: pointer;
                                            "
										></div>
									</td>
								</tr>
								<tr class="order-mian-list" v-for="(j, i) in item.DetailList" :key="i">
									<td style="display: flex" class="border">
										<div><img :src="pic + j.ProPictrue" style="cursor: pointer;" @click="handleTo(j)" alt="" /></div>
										<div class="order-mian-list-text">
											<div class="proName" style="cursor: pointer;" @click="handleTo(j)">{{ j.ProName }}</div>
											<div class="sku">SKU: {{ j.BarCode }}</div>
										</div>
									</td>
									<td class="border">
										<div>￥{{ j.ProUnitPrice }}</div>
									</td>
									<td class="border">
										<div>X {{ j.PCount }}</div>
									</td>
									<td class="border-right">
										<div class="operation"></div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 1" @click="view(j)">审核中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 6" @click="view(j)">退款中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 7" @click="view(j)">退款完成</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 8" @click="view(j)">退款失败</div>
										<div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;" v-if="j.ExState == 9">
											<div style="color: #DC2310;cursor: pointer;" @click="view(j)">部分已退款</div>
											<div style="cursor: pointer;" class="button" @click="refund(item, j)"><span>申请售后</span></div>
										</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 10" @click="view(j)">退换审核失败</div>
										<el-button size="mini" v-if="(item.State == 1 || item.State == 2 || item.State == 3) && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
										<el-button size="mini" v-if="item.State == 4 && item.show && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div class="subtotal">￥{{ item.TotalPrice.toFixed(2) }}</div>
										<div class="button">
											<span v-if="item.OrderType == 1">手机订单</span>
											<span v-else>电脑订单</span>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 6"><div style="color:#999999;margin-top:10px">交易关闭</div></div>

											<div>
												<span v-if="item.State == 0" class="stay">待付款</span>
												<span v-if="item.State == 1" style="color: #1B98AA; ">待发货</span>
												<span v-if="item.State == 2" style="color: #1B98AA; ">卖家已发货</span>
												<span v-if="item.State == 3" style="color: #999999;">待评价</span>
												<span v-if="item.State == 4" style="color: #999999;">已完成</span>
												<span v-if="item.State == 5" class="cancel-order">已取消</span>
											</div>

											<el-popover placement="bottom" width="300" @show="showLogistics(item)" trigger="hover">
												<div class="information">
													<div class="information-title">{{ information.ComName }} {{ item.TrackingNum }}</div>
													<div class="information-main" v-for="(item, index) in information.TrackList" :key="index">
														<div>
															<span style="color:rgb(27, 152, 170)">{{ item.ftime }}</span>
															{{ item.context }}
														</div>
													</div>
												</div>
												<div slot="reference" class="track" v-if="item.State == 2">
													<i class="iconfont iconwuliu"></i>
													<span>追踪</span>
												</div>
											</el-popover>
											<div class="order-details" @click="toOrderDetails(item)">订单详情</div>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 0">
												<div class="paidui">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.time }}</span>
												</div>
												<div v-if="item.time != '已作废'" class="handle" @click="handlePay(item)">支付</div>
												<div v-if="item.time != '已作废'" class="cancel" @click="cancel(item)">取消订单</div>
											</div>
											<div class="remind" @click="remind(item)" v-if="item.State == 1">提醒发货</div>
											<div v-if="item.State == 2">
												<div class="paidui" v-if="!item.EX">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.confirmTime }}</span>
												</div>
												<div @click="confirm(item)" class="confirm">确认收货</div>
											</div>
											<div v-if="item.State == 3"><div @click="toEvaluate(item)" class="confirm">去评价</div></div>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="pagination">
						<div>
							<el-pagination
								background
								@size-change="changeSize"
								@current-change="changeCurrent"
								:page-sizes="[10, 50, 100, 200]"
								:page-size="20"
								layout="total, sizes, prev, pager, next, jumper"
								:total="count"
							></el-pagination>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane>
				<span slot="label">待发货</span>
				<div class="search">
					<div><input type="text" v-model="parameter.KeyWord" placeholder="订单号/商品名称/商品编号" /></div>
					<div class="icon"><i style="cursor: pointer" class="iconfont iconsearch" @click="search"></i></div>
					<div class="select">
						<el-select v-model="parameter.DateType" @change="init" placeholder="请选择" size="medium">
							<el-option label="近三个月订单" :value="1"></el-option>
							<el-option label="近六个月订单" :value="2"></el-option>
							<el-option label="全部订单" :value="0"></el-option>
						</el-select>
					</div>
				</div>
				<div class="list">
					<div>
						<table border="0" cellspacing="0" cellpadding="0">
							<tr class="list-title">
								<th width="450">商品信息</th>
								<th width="110">单价(元)</th>
								<th width="110">数量</th>
								<th width="110">商品操作</th>
								<th width="110">实付款(元)</th>
								<th width="110">全部状态</th>
								<th width="180">交易操作</th>
							</tr>
						</table>

						<!--订单号-->
						<div v-if="cartList.length > 0">
							<table
								style="border-collapse:separate;
                                border-spacing:0 10px;"
								border="0"
								cellspacing="0"
								cellpadding="0"
								v-for="(item, index) in cartList"
								:key="index"
							>
								<tr class="order-mian" style="margin:10px">
									<td
										width="450"
										style="padding-left:10px;
                                        color: #999999;"
									>
										{{ item.CreatTime }} 订单号
										<span class="order-number">{{ item.OrderID }}</span>
									</td>
									<td width="110" v-for="j in 5" :key="j"></td>
									<td width="220" style="text-align:right">
										<div @click="delOrder(item)" v-if="item.State == 5 || item.State == 4 || item.State == 3" style="margin-right:30px;margin-top:5px">
											<i class="iconfont icondustbin_icon"></i>
										</div>

										<div
											style="margin-top:5px;
                                            cursor: pointer;
                                            "
										></div>
									</td>
								</tr>
								<tr class="order-mian-list" v-for="(j, i) in item.DetailList" :key="i">
									<td style="display: flex" class="border">
										<div><img :src="pic + j.ProPictrue" style="cursor: pointer;" @click="handleTo(j)" alt="" /></div>
										<div class="order-mian-list-text">
											<div class="proName" style="cursor: pointer;" @click="handleTo(j)">{{ j.ProName }}</div>
											<div class="sku">SKU: {{ j.BarCode }}</div>
										</div>
									</td>
									<td class="border">
										<div>￥{{ j.ProUnitPrice }}</div>
									</td>
									<td class="border">
										<div>X {{ j.PCount }}</div>
									</td>
									<td class="border-right">
										<div class="operation"></div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 1" @click="view(j)">审核中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 6" @click="view(j)">退款中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 7" @click="view(j)">退款完成</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 8" @click="view(j)">退款失败</div>
										<div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;" v-if="j.ExState == 9">
											<div style="color: #DC2310;cursor: pointer;" @click="view(j)">部分已退款</div>
											<div style="cursor: pointer;" class="button" @click="refund(item, j)"><span>申请售后</span></div>
										</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 10" @click="view(j)">退换审核失败</div>
										<el-button size="mini" v-if="(item.State == 1 || item.State == 2 || item.State == 3) && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
										<el-button size="mini" v-if="item.State == 4 && item.show && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div class="subtotal">￥{{ item.TotalPrice.toFixed(2) }}</div>
										<div class="button">
											<span v-if="item.OrderType == 1">手机订单</span>
											<span v-else>电脑订单</span>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 6"><div style="color:#999999;margin-top:10px">交易关闭</div></div>

											<div>
												<span v-if="item.State == 0" class="stay">待付款</span>
												<span v-if="item.State == 1" style="color: #1B98AA; ">待发货</span>
												<span v-if="item.State == 2" style="color: #1B98AA; ">卖家已发货</span>
												<span v-if="item.State == 3" style="color: #999999;">待评价</span>
												<span v-if="item.State == 4" style="color: #999999;">已完成</span>
												<span v-if="item.State == 5" class="cancel-order">已取消</span>
											</div>

											<el-popover placement="bottom" width="300" @show="showLogistics(item)" trigger="hover">
												<div class="information">
													<div class="information-title">{{ information.ComName }} {{ item.TrackingNum }}</div>
													<div class="information-main" v-for="(item, index) in information.TrackList" :key="index">
														<div>
															<span style="color:rgb(27, 152, 170)">{{ item.ftime }}</span>
															{{ item.context }}
														</div>
													</div>
												</div>
												<div slot="reference" class="track" v-if="item.State == 2">
													<i class="iconfont iconwuliu"></i>
													<span>追踪</span>
												</div>
											</el-popover>
											<div class="order-details" @click="toOrderDetails(item)">订单详情</div>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 0">
												<div class="paidui">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.time }}</span>
												</div>
												<div v-if="item.time != '已作废'" class="handle" @click="handlePay(item)">支付</div>
												<div v-if="item.time != '已作废'" class="cancel" @click="cancel(item)">取消订单</div>
											</div>
											<div class="remind" @click="remind(item)" v-if="item.State == 1">提醒发货</div>
											<div v-if="item.State == 2">
												<div class="paidui" v-if="!item.EX">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.confirmTime }}</span>
												</div>
												<div @click="confirm(item)" class="confirm">确认收货</div>
											</div>
											<div v-if="item.State == 3"><div @click="toEvaluate(item)" class="confirm">去评价</div></div>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="pagination">
						<div>
							<el-pagination
								background
								@size-change="changeSize"
								@current-change="changeCurrent"
								:page-sizes="[10, 50, 100, 200]"
								:page-size="20"
								layout="total, sizes, prev, pager, next, jumper"
								:total="count"
							></el-pagination>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane>
				<span slot="label">待收货</span>
				<div class="search">
					<div><input type="text" v-model="parameter.KeyWord" placeholder="订单号/商品名称/商品编号" /></div>
					<div class="icon"><i style="cursor: pointer" class="iconfont iconsearch" @click="search"></i></div>
					<div class="select">
						<el-select v-model="parameter.DateType" @change="init" placeholder="请选择" size="medium">
							<el-option label="近三个月订单" :value="1"></el-option>
							<el-option label="近六个月订单" :value="2"></el-option>
							<el-option label="全部订单" :value="0"></el-option>
						</el-select>
					</div>
				</div>
				<div class="list">
					<div>
						<table border="0" cellspacing="0" cellpadding="0">
							<tr class="list-title">
								<th width="450">商品信息</th>
								<th width="110">单价(元)</th>
								<th width="110">数量</th>
								<th width="110">商品操作</th>
								<th width="110">实付款(元)</th>
								<th width="110">全部状态</th>
								<th width="180">交易操作</th>
							</tr>
						</table>

						<!--订单号-->
						<div v-if="cartList.length > 0">
							<table
								style="border-collapse:separate;
                                border-spacing:0 10px;"
								border="0"
								cellspacing="0"
								cellpadding="0"
								v-for="(item, index) in cartList"
								:key="index"
							>
								<tr class="order-mian" style="margin:10px">
									<td
										width="450"
										style="padding-left:10px;
                                        color: #999999;"
									>
										{{ item.CreatTime }} 订单号
										<span class="order-number">{{ item.OrderID }}</span>
									</td>
									<td width="110" v-for="j in 5" :key="j"></td>
									<td width="220" style="text-align:right">
										<div @click="delOrder(item)" v-if="item.State == 5 || item.State == 4 || item.State == 3" style="margin-right:30px;margin-top:5px">
											<i class="iconfont icondustbin_icon"></i>
										</div>

										<div
											style="margin-top:5px;
                                            cursor: pointer;
                                            "
										></div>
									</td>
								</tr>
								<tr class="order-mian-list" v-for="(j, i) in item.DetailList" :key="i">
									<td style="display: flex" class="border" width="450">
										<div><img :src="pic + j.ProPictrue" style="cursor: pointer;" @click="handleTo(j)" alt="" /></div>
										<div class="order-mian-list-text">
											<div class="proName" style="cursor: pointer;" @click="handleTo(j)">{{ j.ProName }}</div>
											<div class="sku">SKU: {{ j.BarCode }}</div>
										</div>
									</td>
									<td class="border" width="120">
										<div>￥{{ j.ProUnitPrice }}</div>
									</td>
									<td class="border" width="110">
										<div>X {{ j.PCount }}</div>
									</td>
									<td class="border-right" width="110">
										<div class="operation"></div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 1" @click="view(j)">审核中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 6" @click="view(j)">退款中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 7" @click="view(j)">退款完成</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 8" @click="view(j)">退款失败</div>
										<div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;" v-if="j.ExState == 9">
											<div style="color: #DC2310;cursor: pointer;" @click="view(j)">部分已退款</div>
											<div style="cursor: pointer;" class="button" @click="refund(item, j)"><span>申请售后</span></div>
										</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 10" @click="view(j)">退换审核失败</div>
										<el-button size="mini" v-if="(item.State == 1 || item.State == 2 || item.State == 3) && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
										<el-button size="mini" v-if="item.State == 4 && item.show && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
									</td>
									<td class="border-right" width="110" :rowspan="item.DetailList.length" v-if="i == 0">
										<div class="subtotal">￥{{ item.TotalPrice.toFixed(2) }}</div>
										<div class="button">
											<span v-if="item.OrderType == 1">手机订单</span>
											<span v-else>电脑订单</span>
										</div>
									</td>
									<td class="border-right" width="110" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 6"><div style="color:#999999;margin-top:10px">交易关闭</div></div>

											<div>
												<span v-if="item.State == 0" class="stay">待付款</span>
												<span v-if="item.State == 1" style="color: #1B98AA; ">待发货</span>
												<span v-if="item.State == 2" style="color: #1B98AA; ">卖家已发货</span>
												<span v-if="item.State == 3" style="color: #999999;">待评价</span>
												<span v-if="item.State == 4" style="color: #999999;">已完成</span>
												<span v-if="item.State == 5" class="cancel-order">已取消</span>
											</div>

											<el-popover placement="bottom" width="300" @show="showLogistics(item)" trigger="hover">
												<div class="information">
													<div class="information-title">{{ information.ComName }} {{ item.TrackingNum }}</div>
													<div class="information-main" v-for="(item, index) in information.TrackList" :key="index">
														<div>
															<span style="color:rgb(27, 152, 170)">{{ item.ftime }}</span>
															{{ item.context }}
														</div>
													</div>
												</div>
												<div slot="reference" class="track" v-if="item.State == 2">
													<i class="iconfont iconwuliu"></i>
													<span>追踪</span>
												</div>
											</el-popover>
											<div class="order-details" @click="toOrderDetails(item)">订单详情</div>
										</div>
									</td>
									<td class="border-right" width="110" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 0">
												<div class="paidui">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.time }}</span>
												</div>
												<div v-if="item.time != '已作废'" class="handle" @click="handlePay(item)">支付</div>
												<div v-if="item.time != '已作废'" class="cancel" @click="cancel(item)">取消订单</div>
											</div>
											<div class="remind" @click="remind(item)" v-if="item.State == 1">提醒发货</div>
											<div v-if="item.State == 2">
												<div class="paidui" v-if="!item.EX">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.confirmTime }}</span>
												</div>
												<div @click="confirm(item)" class="confirm">确认收货</div>
											</div>
											<div v-if="item.State == 3"><div @click="toEvaluate(item)" class="confirm">去评价</div></div>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="pagination">
						<div>
							<el-pagination
								background
								@size-change="changeSize"
								@current-change="changeCurrent"
								:page-sizes="[10, 50, 100, 200]"
								:page-size="20"
								layout="total, sizes, prev, pager, next, jumper"
								:total="count"
							></el-pagination>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane>
				<span slot="label">待评价</span>
				<div class="search">
					<div><input type="text" v-model="parameter.KeyWord" placeholder="订单号/商品名称/商品编号" /></div>
					<div class="icon"><i style="cursor: pointer" class="iconfont iconsearch" @click="search"></i></div>
					<div class="select">
						<el-select v-model="parameter.DateType" @change="init" placeholder="请选择" size="medium">
							<el-option label="近三个月订单" :value="1"></el-option>
							<el-option label="近六个月订单" :value="2"></el-option>
							<el-option label="全部订单" :value="0"></el-option>
						</el-select>
					</div>
				</div>
				<div class="list">
					<div>
						<table border="0" cellspacing="0" cellpadding="0">
							<tr class="list-title">
								<th width="450">商品信息</th>
								<th width="110">单价(元)</th>
								<th width="110">数量</th>
								<th width="110">商品操作</th>
								<th width="110">实付款(元)</th>
								<th width="110">全部状态</th>
								<th width="180">交易操作</th>
							</tr>
						</table>

						<!--订单号-->
						<div v-if="cartList.length > 0">
							<table
								style="border-collapse:separate;
                                border-spacing:0 10px;"
								border="0"
								cellspacing="0"
								cellpadding="0"
								v-for="(item, index) in cartList"
								:key="index"
							>
								<tr class="order-mian" style="margin:10px">
									<td
										width="450"
										style="padding-left:10px;
                                        color: #999999;"
									>
										{{ item.CreatTime }} 订单号
										<span class="order-number">{{ item.OrderID }}</span>
									</td>
									<td width="110" v-for="j in 5" :key="j"></td>
									<td width="220" style="text-align:right">
										<div @click="delOrder(item)" v-if="item.State == 5 || item.State == 4 || item.State == 3" style="margin-right:30px;margin-top:5px">
											<i class="iconfont icondustbin_icon"></i>
										</div>

										<div
											style="margin-top:5px;
                                            cursor: pointer;
                                            "
										></div>
									</td>
								</tr>
								<tr class="order-mian-list" v-for="(j, i) in item.DetailList" :key="i">
									<td style="display: flex" class="border">
										<div><img :src="pic + j.ProPictrue" style="cursor: pointer;" @click="handleTo(j)" alt="" /></div>
										<div class="order-mian-list-text">
											<div class="proName" style="cursor: pointer;" @click="handleTo(j)">{{ j.ProName }}</div>
											<div class="sku">SKU: {{ j.BarCode }}</div>
										</div>
									</td>
									<td class="border">
										<div>￥{{ j.ProUnitPrice }}</div>
									</td>
									<td class="border">
										<div>X {{ j.PCount }}</div>
									</td>
									<td class="border-right">
										<div class="operation"></div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 1" @click="view(j)">审核中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 6" @click="view(j)">退款中</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 7" @click="view(j)">退款完成</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 8" @click="view(j)">退款失败</div>
										<div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;" v-if="j.ExState == 9">
											<div style="color: #DC2310;cursor: pointer;" @click="view(j)">部分已退款</div>
											<div style="cursor: pointer;" class="button" @click="refund(item, j)"><span>申请售后</span></div>
										</div>
										<div style="color: #DC2310;cursor: pointer;" v-if="j.ExState == 10" @click="view(j)">退换审核失败</div>
										<el-button size="mini" v-if="(item.State == 1 || item.State == 2 || item.State == 3) && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
										<el-button size="mini" v-if="item.State == 4 && item.show && j.ExState == 0" @click="refund(item, j)">申请售后</el-button>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div class="subtotal">￥{{ item.TotalPrice.toFixed(2) }}</div>
										<div class="button">
											<span v-if="item.OrderType == 1">手机订单</span>
											<span v-else>电脑订单</span>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 6"><div style="color:#999999;margin-top:10px">交易关闭</div></div>

											<div>
												<span v-if="item.State == 0" class="stay">待付款</span>
												<span v-if="item.State == 1" style="color: #1B98AA; ">待发货</span>
												<span v-if="item.State == 2" style="color: #1B98AA; ">卖家已发货</span>
												<span v-if="item.State == 3" style="color: #999999;">待评价</span>
												<span v-if="item.State == 4" style="color: #999999;">已完成</span>
												<span v-if="item.State == 5" class="cancel-order">已取消</span>
											</div>

											<el-popover placement="bottom" width="300" @show="showLogistics(item)" trigger="hover">
												<div class="information">
													<div class="information-title">{{ information.ComName }} {{ item.TrackingNum }}</div>
													<div class="information-main" v-for="(item, index) in information.TrackList" :key="index">
														<div>
															<span style="color:rgb(27, 152, 170)">{{ item.ftime }}</span>
															{{ item.context }}
														</div>
													</div>
												</div>
												<div slot="reference" class="track" v-if="item.State == 2">
													<i class="iconfont iconwuliu"></i>
													<span>追踪</span>
												</div>
											</el-popover>
											<div class="order-details" @click="toOrderDetails(item)">订单详情</div>
										</div>
									</td>
									<td class="border-right" :rowspan="item.DetailList.length" v-if="i == 0">
										<div>
											<div v-if="item.State == 0">
												<div class="paidui">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.time }}</span>
												</div>
												<div v-if="item.time != '已作废'" class="handle" @click="handlePay(item)">支付</div>
												<div v-if="item.time != '已作废'" class="cancel" @click="cancel(item)">取消订单</div>
											</div>
											<div class="remind" @click="remind(item)" v-if="item.State == 1">提醒发货</div>
											<div v-if="item.State == 2">
												<div class="paidui" v-if="!item.EX">
													<i class="iconfont iconpaidui"></i>
													<span>{{ item.confirmTime }}</span>
												</div>
												<div @click="confirm(item)" class="confirm">确认收货</div>
											</div>
											<div v-if="item.State == 3"><div @click="toEvaluate(item)" class="confirm">去评价</div></div>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="pagination">
						<div>
							<el-pagination
								background
								@size-change="changeSize"
								@current-change="changeCurrent"
								:page-sizes="[10, 50, 100, 200]"
								:page-size="20"
								layout="total, sizes, prev, pager, next, jumper"
								:total="count"
							></el-pagination>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { getMyOrder, removeOrder, applyEx, orderPayVerification } from './service';

import { agreenOrder, cancleOrder, queryInformation, sendRemind } from '../payment/service';

export default {
	name: 'order',
	data() {
		return {
			value: 3,
			activeName: '',
			count: 0,
			information: [],
			parameter: {
				PageIndex: 1,
				PageSize: 10,
				DateType: ''
			},
			pic: FILE_URL,
			cartList: []
		};
	},
	mounted() {
		this.init();
		setInterval(() => {
			this.cartList.splice(0, 0);
		}, 1000);
	},
	methods: {
		toChat(order) {		
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')

			// let proName = [];
			// order.DetailList.forEach(v => {
			// 	proName.push(v.ProName);
			// });
			// this.ysf('product', {
			// 	show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
			// 	title: '订单号：' + order.OrderID,
			// 	desc: '订单商品：' + proName.join('/'),
			// 	picture: FILE_URL + order.DetailList[0].ProPictrue,
			// 	note: '实付金额：￥' + order.TotalPrice,
			// 	success: () => {
			// 		// 成功回调
			// 		this.ysf('open');
			// 	},
			// 	error: () => {
			// 		// 错误回调
			// 		this.ysf('open');
			// 	}
			// });
		},
		view(j) {
			this.$router.push({
				path: `/me/goodsDetails?OrderID=${j.ExOrderID}`
			});
		},
		handleTo(data) {
			let u = localStorage.getItem('u');
			window.open(`/commodity?pid=${data.PID}&u=${u}`, '_blank').location;
		},
		// 退款
		refund(item, j) {
			applyEx({ OrderID: item.OrderID }).then(res => {
				if (res.code == 1) {
					if (item.State == 1) {
						this.$router.push({
							path: `/me/fillIn?CreatTime=${j.CreatTime}&OrderID=${j.OrderID}&ProName=${j.ProName}&ProUnitPrice=${j.ProUnitPrice}&PCount=${j.PCount}&DisPrice=${
								j.DisPrice
							}&LastPrice=${j.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${j.TaxPrice}&PID=${j.PID}&type=1`
						});
					} else {
						this.$router.push({
							path: `/typeGoods?CreatTime=${j.CreatTime}&OrderID=${j.OrderID}&ProName=${j.ProName}&ProUnitPrice=${j.ProUnitPrice}&PCount=${j.PCount}&DisPrice=${
								j.DisPrice
							}&LastPrice=${j.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${j.TaxPrice}&PID=${j.PID}`
						});
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// tabs切换
		chaneTabs() {
			switch (this.activeName) {
				case '0':
					this.parameter = {
						PageIndex: 1,
						PageSize: 10,
						DateType: '',
						State: ''
					};
					break;
				case '1':
					this.parameter = {
						PageIndex: 1,
						PageSize: 10,
						DateType: '',
						State: 0
					};
					break;
				case '2':
					this.parameter = {
						PageIndex: 1,
						PageSize: 10,
						DateType: '',
						State: 1
					};
					break;
				case '3':
					this.parameter = {
						PageIndex: 1,
						PageSize: 10,
						DateType: '',
						State: 2
					};
					break;
				case '4':
					this.parameter = {
						PageIndex: 1,
						PageSize: 10,
						DateType: '',
						State: 3
					};
					break;
			}
			this.init();
		},
		toLesson(url) {
			window.open(url, '_blank').location;
		},
		// 订单详情
		toOrderDetails(data) {
			window.open(`/me/orderDetails?OrderID=${data.OrderID}`, '_blank').location;
		},
		// 去评价
		toEvaluate(data) {
			window.open(`/me/toEvaluate?OrderID=${data.OrderID}`, '_blank').location;
		},
		// 确认收货
		confirm(data) {
			this.$confirm('你确定要执行吗, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				agreenOrder({
					OrderID: data.OrderID
				}).then(() => {
					this.$message.success('收货完成');
					this.init();
				});
			});
		},
		// 提醒发货
		remind(data) {
			sendRemind({
				OrderID: data.OrderID
			}).then(() => {
				this.$message.success('提醒成功');
				this.init();
			});
		},
		// 查询物流
		showLogistics(data) {
			if (!data.TrackingNum) {
				this.$message.error('暂无没有物流信息');
				return;
			}
			queryInformation({
				OrderID: data.OrderID
			}).then(res => {
				this.information = res.data;
			});
		},
		// 取消订单
		cancel(data) {
			this.$confirm('你确定要执行吗, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				cancleOrder({
					OrderID: data.OrderID
				}).then(() => {
					this.$message.success('取消成功');
					this.init();
				});
			});
		},
		handlePay(data) {
			orderPayVerification({
				OrderID: data.OrderID
			}).then(res => {
				if (res.code == 0) {
					this.$message.error(res.msg);
				} else {
					this.$router.push({
						path: `/payment?orderID=${data.OrderID}`
					});
				}
				// console.log(res);
			});

			// window.open(`/payment?orderID=${data.OrderID}`, '_blank').location
		},
		changeSize(size) {
			this.parameter.PageSize = size;
			this.init();
		},
		search() {
			this.parameter.PageIndex = 1;
			this.parameter.PageSize = 10;
			this.init();
		},
		changeCurrent(size) {
			this.parameter.PageIndex = size;
			this.init();
		},

		init() {
			getMyOrder(this.parameter).then(res => {
				for (let item in res.data) {
					res.data[item].time = '00小时00分00秒';
					res.data[item].confirmTime = '00小时00分00秒';
					if (res.data[item].State == 0) {
						setInterval(() => {
							let dateTime = new Date(res.data[item].CreatTime);
							dateTime = dateTime.setDate(dateTime.getDate() + 7);
							dateTime = new Date(dateTime);
							res.data[item].time = this.showtime(dateTime);
						}, 1000);
					}
					if (res.data[item].State == 2) {
						setInterval(() => {
							let dateTime = new Date(res.data[item].ShipmentsTime);
							dateTime = dateTime.setDate(dateTime.getDate() + 12);
							dateTime = new Date(dateTime);
							res.data[item].confirmTime = this.showtime(dateTime);
						}, 1000);
					}
					if (res.data[item].State == 4) {
						this.order = res.data;
						var dateTime = new Date(res.data[item].OverTime);
						var dateTime1 = new Date();
						dateTime = dateTime.setDate(dateTime.getDate() + 14);
						dateTime = new Date(dateTime);

						if (dateTime < dateTime1) {
							res.data[item].show = false;
						} else {
							res.data[item].show = true;
						}
					}
				}

				for (let item in res.data) {
					for (let j in res.data[item].DetailList) {
						if (res.data[item].DetailList[j].ExState != 6) {
							res.data[item].EX = false;
						}
					}
				}

				this.cartList = res.data;
				this.count = res.count;
			});
		},
		delOrder(item) {
			this.$confirm('你确定要执行吗, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				removeOrder({
					OrderID: item.OrderID
				}).then(() => {
					this.$message.success('删除成功');
					this.init();
				});
			});
		},
		showtime(data) {
			let nowtime = new Date(), //获取当前时间
				endtime = new Date(data); //定义结束时间
			let lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
				days = parseInt(lefttime / 1000 / 60 / 60 / 24), //计算剩余的天数
				lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
				leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
				lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
			if (lefts.length == 1) {
				lefts = '0' + lefts;
			}
			lefth = lefth >= 0 && lefth < 10 ? '0' + lefth : '' + lefth;
			leftm = leftm >= 0 && leftm < 10 ? '0' + leftm : '' + leftm;
			lefts = lefts >= 0 && lefts < 10 ? '0' + lefts : '' + lefts;
			this.time = lefth + '小时' + leftm + '分' + lefts + '秒';
			if (lefth.startsWith('-')) {
				return '已作废';
			} else {
				return days + '天' + lefth + '小时' + leftm + '分' + lefts + '秒';
			}
		}
	}
};
</script>
<style lang="less" scoped>
.proName {
	width: 200px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 14px;
}
.information {
	text-align: center;
	max-height: 300px;
	font-size: 12px;
	overflow-y: scroll;
	.information-title {
		color: #666666;
		padding-bottom: 10px;
		border-bottom: 1px solid #f4f4f4;
	}

	.information-main {
		margin-top: 5px;
		color: #999999;
	}
}

.confirm {
	background: #1b98aa;
	color: #ffffff;
	width: 80px;
	padding: 5px 0;
	margin: 10px auto;
	cursor: pointer;
}

.stay {
	color: #ff2323;
}

.track {
	margin-top: 12px;
	cursor: pointer;

	i {
		margin-right: 3px;

		span {
			margin-top: -10px;
		}
	}
}

.pagination {
	display: flex;
	margin-top: 30px;
	justify-content: flex-end;
	margin-right: 30px;
}

.search {
	display: flex;

	input {
		background: #eeeeee;
		border: none;
		outline: none;
		width: 250px;
		height: 35px;
		text-indent: 1rem;
	}

	.icon {
		width: 37px;
		height: 37px;
		line-height: 37px;
		background: #eeeeee;
		margin-left: 3px;
		text-align: center;

		i {
			font-size: 18px;
			color: #666666;
		}
	}

	.select {
		margin-left: 20px;
	}
}
.sku {
	width: fit-content;
	padding: 2px 10px;
	background: #f5f5f5;
	border: 1px solid #dedede;
	border-radius: 15px;
	color: #333333;
	font-size: 12px;
	margin: 10px 0px;
}

.list {
	margin-top: 10px;

	.list-title {
		background: #eeeeee;
		height: 40px;
		text-align: center;
	}

	.order-mian {
		width: 1300px;
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		margin: 10px 0;
		background: #f9f9f9;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.order-mian-list {
		text-align: center;
		font-size: 14px;
		margin: 10px 0;
	}

	.order-mian-list-text {
		text-align: left;
		margin: 10px;
	}

	.subtotal {
		font-family: 'ct';
		color: #1b98aa;
		font-size: 20px;
	}

	.button {
		width: fit-content;
		padding: 2px 10px;
		margin: 10px auto;
		background: rgba(27, 152, 170, 0);
		border: 1px solid #d9d9d9;
		color: #999999;
	}

	img {
		width: 100px;
		height: 100px;
	}

	.border {
		padding-bottom: 10px;
		border-bottom: 1px solid #f4f4f4;
	}

	.border-left {
		border-left: 1px solid #f4f4f4;
	}

	.border-right {
		border-right: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
	}

	.order-details {
		margin: 10px 0;
		cursor: pointer;
	}

	.paidui {
		color: #999999;
		span {
			margin-left: 5px;
		}
	}

	.handle {
		width: fit-content;
		margin: 10px auto;
		padding: 5px 15px;
		color: #ffffff;
		cursor: pointer;
		background: #dc2310;
	}
}

tr {
	margin: 10px;
}

.cancel {
	cursor: pointer;
}

.cancel-order {
	color: #999999;
}

.remind {
	cursor: pointer;
}
</style>

<style scoped>
>>> .el-input__inner {
	border-radius: 0;
}

>>> .el-tabs {
	width: 1066px;
}

>>> .el-tabs__item {
	font-family: 'ct';
	font-size: 15px;
	color: #333333;
}

>>> .el-tabs__item.is-active {
	color: #1b98aa;
}

>>> .el-tabs__nav-wrap::after {
	height: 1px;
}

>>> .el-tabs__active-bar {
	height: 3px;
	background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
}
</style>
