import { post } from '@/http'

// 我的订单列表
export function getMyOrder(data) {
    return new Promise((resolve, reject) => {
        post('/Order/GetMyOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 获取订单详情
export function getOrderInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Order/GetOrderInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 删除订单
export function removeOrder(data) {
    return new Promise((resolve, reject) => {
        post('/Order/RemoveOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function applyEx(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/ApplyEx', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function orderPayVerification(data) {
    return new Promise((resolve, reject) => {
        post('/Order/OrderPayVerification', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
